import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import Seoinfo from "../components/seoinfo/seoinfo";
import seoImage from "../../static/seo/seo_image.png";

import GetInTouchSection from "../components/GetInTouchSection";
import Hero from "../images/faq/webp/img.webp";
import HeroMobile from "../images/faq/webp/img_mobile.webp";
import HeroTablet from "../images/faq/webp/img_768.webp";
import {
  BannerContainer,
  BannerImageMobile,
  BannerImageTablet,
  BannerImageWeb,
  Description,
  HeroTextDiv,
  InternalWrapper,
  MainContainer,
  Title,
} from "../styles/pages/faq";
import FaqDropdown from "../components/FaqDropdown";
import { CONTACT } from "../config/urls";
import AOS from "aos";
import { HelmetProvider } from "react-helmet-async";

const Faq = () => {
  const [isClient, setIsClient] = useState(false);

  useEffect(
    () => {
      AOS.init({
        // initialise with other settings
        duration: 2000,
        once: true,
      });
    },
    [],
    AOS
  );

  useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <HelmetProvider key={isClient ? "client" : "server"}>
      <Layout hasTransparentTopBar>
        <Seoinfo
          siteName="Home Care FAQ - Your Questions Answered | Life Home Care"
          titlePage="Home Care FAQ - Your Questions Answered | Life Home Care"
          description="Find answers to common questions about home care services, costs, benefits, and more. Get informed to make the best care decisions for your loved ones."
          keywords="Home, Care, HomeCare, Senior, Compassionate, Care, Home Care Agency, Home Care Agencies"
          type="website"
          url="/faq"
          imageFacebook={seoImage}
          canonical
        />
        <MainContainer>
          <BannerContainer>
            <BannerImageWeb
              src={Hero}
              alt="A doctor with her patient"
              loading="lazy"
            />
            <BannerImageTablet
              src={HeroTablet}
              alt="A doctor with her patient"
              loading="lazy"
            />
            <BannerImageMobile
              src={HeroMobile}
              alt="A doctor with her patient"
              loading="lazy"
            />
          </BannerContainer>
          <InternalWrapper>
            <HeroTextDiv>
              <Title>LIFE HOMECARE</Title>
              <Description>
                Empowering Independence with Compassion: Your Complete Guide to
                Home Care Agency Services. Discover answers to your most
                pressing questions about our personalized, in-home care
                solutions provided by our dedicated agency, enhancing the
                quality of life for you and your loved ones.
              </Description>
            </HeroTextDiv>
          </InternalWrapper>
        </MainContainer>
        <FaqDropdown />
        <GetInTouchSection contactEmail={CONTACT.MAIL_CONTACT_US_LOCATIONS} />
      </Layout>
    </HelmetProvider>
  );
};

export default Faq;
